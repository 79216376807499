import { FC } from 'react';

import { View, Text, Flex } from '@instructure/ui';

import AstroRocketIcon from '../assets/astro-rocket.svg';
import { useLibTrans } from '../i18n';

type NoDataProps = {
    messages: string[];
};

const LoadFailed: FC<NoDataProps> = ({ messages }) => {
    const { t } = useLibTrans('moduleAppLoader', undefined, { useSuspense: false });

    return (
        <View as="div" width="100%">
            <Flex as="div" margin="medium auto">
                <Flex.Item as="span" margin="medium auto">
                    <AstroRocketIcon />
                </Flex.Item>
            </Flex>
            <Flex as="div" direction="column" margin="medium auto">
                <Flex.Item as="span" margin="auto">
                    <Text size="large" weight="bold">
                        {t('apploader.error_occured')}
                    </Text>
                </Flex.Item>
                {messages.map((message, index) => (
                    <Flex.Item key={index} as="span" margin="auto">
                        <Text size="large" weight="light">
                            - {message}
                        </Text>
                    </Flex.Item>
                ))}
                <Flex.Item as="span" margin="medium auto">
                    <Text size="large" weight="bold">
                        {t('apploader.contact_support_message')}
                    </Text>
                </Flex.Item>
            </Flex>
        </View>
    );
};

export default LoadFailed;
