import { Card, logoMap } from './components/Card';
import { ltiAppNames } from '../common/ltiAppNames';
import { LtiAppDefinition } from '../app/app-loader/hooks/useLoadLtiAppDefinitions';

function hrefBuilder(accountId: string, ltiAppDefinition: LtiAppDefinition, internalPath?: string) {
    const queryParams = new URLSearchParams();
    queryParams.set('display', 'full_width');
    queryParams.set('url', ltiAppDefinition?.url.toString() ?? '');

    if (internalPath) {
        queryParams.set('com_instructure_course_canvas_resource_type', 'page');
        queryParams.set('com_instructure_course_canvas_resource_id', internalPath);
    }

    return `/accounts/${accountId}/external_tools/retrieve?${queryParams.toString()}`;
}

export const getCardDefinitions: (accountId: string, ltiApps: Record<ltiAppNames, LtiAppDefinition>) => Card[] = (
    accountId,
    ltiApps
) => [
    {
        title: 'doowii.title',
        description: 'doowii.description',
        logo: 'predictive',
        menus: [
            {
                title: 'doowii.menu.ask_your_data.title',
                href: hrefBuilder(accountId, ltiApps['Ask Your Data']),
                intelligentInsights: true,
                popoverDescription: 'standard_badges.popover.description',
                ltiAppName: 'Ask Your Data',
                permission: 'view_ask_questions_analytics',
                productAnalyticsId: 'launch-Ask Your Data-default',
            },
        ],
    },
    {
        title: 'usage_adoption.title',
        description: 'usage_adoption.description',
        logo: 'analytics',
        menus: [
            {
                title: 'usage_adoption.menu.aa_overview.title',
                href: hrefBuilder(accountId, ltiApps['Admin Analytics'], 'overview'),
                ltiAppName: 'Admin Analytics',
                productAnalyticsId: 'launch-Admin Analytics-overview',
            },
            {
                title: 'usage_adoption.menu.impact.title',
                href: hrefBuilder(accountId, ltiApps['Impact Dashboard LTI 1.3'], 'insights'),
                ltiAppName: 'Impact Dashboard LTI 1.3',
                productAnalyticsId: 'launch-Impact Dashboard LTI 1.3-insights',
            },
            {
                title: 'usage_adoption.menu.lti_usage.title',
                href: hrefBuilder(accountId, ltiApps['LTI Usage LTI 1.3']),
                intelligentInsights: true,
                ltiAppName: 'LTI Usage LTI 1.3',
                popoverDescription: 'usage_adoption.lti_usage.popover.description',
                permission: 'view_lti_usage',
                productAnalyticsId: 'launch-LTI Usage LTI 1.3-default',
            },
        ],
    },
    {
        title: 'student_success.title',
        description: 'student_success.description',
        logo: 'user',
        menus: [
            {
                title: 'student_success.menu.student_engagement.title',
                href: hrefBuilder(accountId, ltiApps['Admin Analytics'], 'student'),
                ltiAppName: 'Admin Analytics',
                productAnalyticsId: 'launch-Admin Analytics-student_overview',
            },
            {
                title: 'student_success.menu.students_in_need.title',
                href: hrefBuilder(accountId, ltiApps['Admin Analytics'], 'students_in_need'),
                intelligentInsights: true,
                popoverDescription: 'standard_badges.popover.description',
                ltiAppName: 'Admin Analytics',
                permission: 'view_students_in_need',
                productAnalyticsId: 'launch-Admin Analytics-students_in_need',
            },
        ],
    },
    {
        title: 'course_effectiveness.title',
        description: 'course_effectiveness.description',
        logo: 'analytics',
        menus: [
            {
                title: 'course_effectiveness.menu.course_overview.title',
                href: hrefBuilder(accountId, ltiApps['Admin Analytics'], 'canvas_course_data'),
                ltiAppName: 'Admin Analytics',
                productAnalyticsId: 'launch-Admin Analytics-course_overview',
            },
            {
                title: 'course_effectiveness.menu.course_readiness.title',
                href: hrefBuilder(accountId, ltiApps['Admin Analytics'], 'course_readiness'),
                intelligentInsights: true,
                popoverDescription: 'standard_badges.popover.description',
                ltiAppName: 'Admin Analytics',
                permission: 'view_course_readiness',
                productAnalyticsId: 'launch-Admin Analytics-course_readiness',
            },
        ],
    },
    {
        title: 'standards_proficiency.title',
        description: 'standards_proficiency.description',
        logo: 'outcomes',
        menus: [
            {
                title: 'standards_proficiency.menu.standards_overview.title',
                href: hrefBuilder(accountId, ltiApps['Mastery Connect'], 'standards'),
                ltiAppName: 'Mastery Connect',
                productAnalyticsId: 'launch-Mastery Connect-standards',
            },
        ],
    },
    {
        title: 'assessment_results.title',
        description: 'assessment_results.description',
        logo: 'assignment',
        menus: [
            {
                title: 'assessment_results.menu.formative_assessments.title',
                href: hrefBuilder(accountId, ltiApps['Mastery Connect'], 'formative'),
                ltiAppName: 'Mastery Connect',
                productAnalyticsId: 'launch-Mastery Connect-formative',
            },
            {
                title: 'assessment_results.menu.benchmark_assessments.title',
                href: hrefBuilder(accountId, ltiApps['Mastery Connect'], 'benchmark'),
                ltiAppName: 'Mastery Connect',
                productAnalyticsId: 'launch-Mastery Connect-benchmark',
            },
            {
                title: 'assessment_results.menu.snail.title',
                href: hrefBuilder(accountId, ltiApps['Mastery Connect'], 'snail'),
                ltiAppName: 'Mastery Connect',
                externalFeatureName: 'state_and_national_assessment',
                productAnalyticsId: 'launch-Mastery Connect-snail',
            },
        ],
    },
    {
        title: 'data_access.title',
        description: 'data_access.description',
        logo: 'sisSynced',
        menus: [
            {
                title: 'data_access.menu.canvas_data_2.title',
                href: 'https://community.canvaslms.com/t5/Admin-Guide/What-is-Canvas-Data-2/ta-p/560956',
                openOnNewTab: true,
                productAnalyticsId: 'launch-DAP-community',
            },
            {
                title: 'data_access.menu.dap_cli_client.title',
                href: 'https://data-access-platform-api.s3.amazonaws.com/client/README.html',
                openOnNewTab: true,
                productAnalyticsId: 'launch-DAP-cli_client_docs',
            },
            {
                title: 'data_access.menu.dap_query_api.title',
                href: 'https://api-gateway.instructure.com/doc/',
                openOnNewTab: true,
                productAnalyticsId: 'launch-DAP-query_api_docs',
            },
        ],
    },
] satisfies Card[];

type intelligentInsights = {
    title: string;
    logo: keyof typeof logoMap;
};

export const intelligentInsights: intelligentInsights[] = [
    {
        title: 'doowii.menu.ask_your_data.title',
        logo: 'predictive',
    },
    {
        title: 'usage_adoption.menu.lti_usage.title',
        logo: 'analytics',
    },
    {
        title: 'course_effectiveness.menu.course_readiness.title',
        logo: 'book',
    },
    {
        title: 'student_success.menu.students_in_need.title',
        logo: 'user',
    },
];
